import React from "react";
import AvatarImage from "../../assets/images/avatar-image.png";

const SectionIntro = () => (
  <section
    id="intro"
    className="container mx-auto p-5 pt-24 sm:pt-20 xl:pt-24 pb-0 text-center"
  >
    <div data-aos="fade-up" className="flex flex-col items-center min-h-75">
      <h2 className="text-4xl sm:text-5xl xl:text-6xl mb-5 sm:mb-3 xl:mb-5 font-normal">
        Hi, I am{" "}
        <span className="rounded-10 bg-brand-blue-500 px-4 text-white font-semibold">
          Lazar
        </span>
        ,
      </h2>
      <p className="text-lg sm:text-2xl font-light">
        a full stack developer, based in Belgrade, Serbia.
      </p>
      <div className="w-56 sm:w-56 xl:w-72 mt-16 sm:mt-10 xl:mt-16">
        <img src={AvatarImage} alt="Lazar Kostic - Avatar" />
      </div>
    </div>
  </section>
);

export default SectionIntro;
