import React, { Component } from "react";
import AOS from "aos";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import SectionIntro from "../components/SectionIntro";
import SectionAbout from "../components/SectionAbout";
import SectionContact from "../components/SectionContact";
import SectionWork from "../components/SectionWork";

class IndexPage extends Component {
  componentDidMount() {
    AOS.init({ duration: 600 });
  }
  render() {
    return (
      <Layout>
        <SEO title="Full-stack Developer" />
        <SectionIntro />
        <SectionAbout />
        <SectionWork />
        <SectionContact />
      </Layout>
    );
  }
}

export default IndexPage;
