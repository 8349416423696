import React from "react";
import { IoIosArrowForward } from "react-icons/io";

import work from "./work.yml";

const ImageCard = ({ name, desc, link }) => {
  return (
    <div className="sm:w-120 lg:w-80 my-8 mx-4 lg:my-0 lg:mx-0">
      <figure className="relative overflow-hidden rounded-lg shadow-2xl cursor-pointer">
        <img
          src={`/images/portfolio/${name}`}
          alt="RMC Assets"
          className="w-full h-full"
        />
        <figcaption className="flex flex-col items-center absolute top-0 left-0 w-full h-full opacity-0 hover:opacity-100 text-white z-10 bg-gray-900 flex flex-col justify-center transition duration-500 ease-in-out p-8">
          <h1 className="mb-6 xl:mb-10 text-base xl:text-lg font-light">
            {desc}
          </h1>
          <a target="_blank" rel="nofollow noopener noreferrer" href={link}>
            <div className="flex items-center justify-center border-2 border-brand-blue-100 hover:bg-brand-blue-100 text-white rounded-2xl px-5 py-2 font-normal transition duration-300 ease-in-out hover:text-white">
              <span className="mr-1 font-light">Visit website</span>
              <IoIosArrowForward size={15} />
            </div>
          </a>
        </figcaption>
      </figure>
    </div>
  );
};

const SectionWork = () => (
  <section id="work">
    <div className="lg:h-180 container mx-auto p-5 pt-16 sm:pt-24 pb-0 mb-24 text-center">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl xl:text-4xl font-semibold mb-6">
          What I've been up to.
        </h1>
        <h2 className="text-lg xl:text-xl font-light mb-16">
          Here is the selection of some recent work. Want to see more?{" "}
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@lazarkostic.com"
              className="text-brand-blue-500 font-normal"
            >
              Email me
            </a>
          </span>
          .
        </h2>
        <div data-aos="fade-up" className="lg:grid grid-cols-3 gap-10">
          {work.map(w => (
            <ImageCard key={w.id} name={w.name} desc={w.desc} link={w.link} />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default SectionWork;
