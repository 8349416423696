import React from "react";
import frontend from "../../assets/images/frontend.svg";
import backend from "../../assets/images/backend.svg";
import skills from "./skills.yml";
import "./index.scss";

const SectionAbout = () => (
  <section id="about">
    <div className="gradient">
      <div className="h-56 gradient-before"></div>
      <div className="h-180 sm:h-150 container mx-auto text-white text-center text-2xl p-5 pt-28 pb-0">
        <div data-aos="fade-up" className="mx-auto w-11/12 sm:w-150 xl:w-180">
          <h1 className="text-2xl sm:text-3xl xl:text-4xl font-bold mb-8">
            Hi, I am Lazar. Nice to meet you.
          </h1>
          <p className="text-lg xl:text-xl font-thin leading-relaxed">
            I am a full stack developer, graduated computer science and software
            engineering at University of Belgrade. I love programming, music,
            art and basketball. Over time, I have gained experience in building
            web applications using various technologies and tools. I'm
            confident, naturally curious, and focused on producing high-quality,
            well tested code.
          </p>
        </div>
      </div>
    </div>
    <div className="-mt-32 sm:-mt-40 xl:-mt-32 mb-16 w-11/12 xl:w-5/6 mx-auto flex flex-col sm:flex-row justify-center bg-white border xl:border-2 rounded-xl">
      <div className="w-full sm:w-1/2 flex flex-col items-center py-16 px-10 sm:px-16 xl:px-36 text-center border-b sm:border-b-0 sm:border-r xl:border-r-2">
        <div className="w-12 mb-10">
          <img src={frontend} alt="frontend" />
        </div>
        <h1 className="text-xl xl:text-2xl font-bold mb-6 xl:mb-8">
          Front-end Developer
        </h1>
        <p className="text-base xl:text-lg font-thin h-16">
          I enjoy coding things from scratch and bringing them to life in
          browser.
        </p>
        <h2 className="text-lg xl:text-xl font-normal text-brand-blue-400 mt-8 mb-3">
          Languages &amp; Libraries:
        </h2>
        <p className="text-base xl:text-lg font-thin">ReactJS</p>
        <h2 className="text-lg xl:text-xl font-normal text-brand-blue-400 mt-8 mb-3">
          Tools &amp; Skills:
        </h2>
        <ul className="text-base xl:text-lg font-thin list-group">
          {skills.frontend.map(skill => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
      </div>
      <div className="w-full sm:w-1/2 flex flex-col items-center py-16 px-10 sm:px-16 xl:px-36 text-center">
        <div className="w-12 mb-10">
          <img src={backend} alt="backend" />
        </div>
        <h1 className="text-xl xl:text-2xl font-bold mb-6 xl:mb-8">
          Back-end Developer
        </h1>
        <p className="text-base xl:text-lg font-thin h-16">
          I love building and working on backend side for web applications.
        </p>
        <h2 className="text-lg xl:text-xl font-normal text-brand-blue-400 mt-8 mb-3">
          Languages &amp; Libraries:
        </h2>
        <p className="text-base xl:text-lg font-thin">NodeJS, ExpressJS</p>
        <h2 className="text-lg xl:text-xl font-normal text-brand-blue-400 mt-8 mb-3">
          Tools &amp; Skills:
        </h2>
        <ul className="text-base xl:text-lg font-thin list-group">
          {skills.backend.map(skill => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default SectionAbout;
