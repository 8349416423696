import React, { Component } from "react";
import { Link } from "react-scroll";
import Logo from "../../../assets/images/logo.png";

class Header extends Component {
  render() {
    return (
      <div className="container mx-auto w-11/12 sm:w-5/6 text-gray-700">
        <div
          data-aos="fade-in"
          className="flex items-center justify-between py-6"
        >
          <a href="/">
            <div className="w-12 xl:w-16 cursor-pointer">
              <img src={Logo} alt="Lazar Kostic - Logo" />
            </div>
          </a>
          <ul className="flex items-center font-light text-normal xl:text-lg">
            <li className="cursor-pointer ">
              <Link
                activeClass=""
                to="about"
                spy={true}
                smooth={true}
                offset={230}
                duration={500}
              >
                About
              </Link>
            </li>
            <li className="ml-4 sm:ml-8 cursor-pointer ">
              <Link
                activeClass=""
                to="work"
                spy={true}
                smooth={true}
                offset={2}
                duration={500}
              >
                Work
              </Link>
            </li>
            <li className="ml-4 sm:ml-8 cursor-pointer">
              <Link
                className="border-2 border-brand-blue-500 hover:bg-brand-blue-500 text-brand-blue-500 rounded-2xl px-5 py-2 font-normal transition duration-300 ease-in-out hover:text-white"
                activeClass=""
                to="contact"
                spy={true}
                smooth={true}
                offset={2}
                duration={500}
              >
                Say Hello
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Header;
