import React, { Component } from "react";
import { FaGithub, FaGitlab, FaLinkedinIn, FaEnvelope } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { animateScroll as scroll } from "react-scroll";

class Footer extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <div className="w-full bg-brand-blue-500">
        <div className="flex flex-col items-center mb-10">
          <button
            onClick={this.scrollToTop}
            className="w-full flex justify-center cursor-pointer mb-12 transition duration-500 easy-in-out transform translate-y-6 hover:transform hover:-translate-y-px outline-none focus:outline-none"
          >
            <IoIosArrowUp size={80} className="text-white" />
          </button>

          <p className="w-48 sm:w-64 xl:w-80 text-center text-white font-light text-sm sm:text-xl mb-12 sm:mb-12">
            Living, learning, &amp; leveling up one day at a time.
          </p>
          <div className="flex flex-row justify-center mb-16 sm:mb-20">
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://www.linkedin.com/in/lazarkostic"
            >
              <div className="mx-4 sm:mx-6 xl:mx-8 text-white cursor-pointer transition duration-200 ease-in-out rounded-full border-2 border-gray-500 p-2 hover:border-white hover:text-brand-blue-600 hover:bg-white">
                <FaLinkedinIn size="23" />
              </div>
            </a>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://github.com/lazarkostic"
            >
              <div className="mx-4 sm:mx-6 xl:mx-8 text-white cursor-pointer transition duration-200 ease-in-out rounded-full border-2 border-gray-500 p-2 hover:border-white hover:text-brand-blue-600 hover:bg-white">
                <FaGithub size="23" />
              </div>
            </a>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://gitlab.com/lazarkostic"
            >
              <div className="mx-4 sm:mx-6 xl:mx-8 text-white cursor-pointer transition duration-200 ease-in-out rounded-full border-2 border-gray-500 p-2 hover:border-white hover:text-brand-blue-600 hover:bg-white">
                <FaGitlab size="23" />
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@lazarkostic.com"
            >
              <div className="mx-4 sm:mx-6 xl:mx-8 text-white cursor-pointer transition duration-200 ease-in-out rounded-full border-2 border-gray-500 p-2 hover:border-white hover:text-brand-blue-600 hover:bg-white">
                <FaEnvelope size="23" />
              </div>
            </a>
          </div>
          <p className="text-center text-white font-thin text-xs opacity-50">
            &copy; 2020 Lazar Kostic. All rights reserved.
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
