import React, { Component } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import "./index.scss";

class SectionContact extends Component {
  state = {
    formFields: {
      name: "",
      email: "",
      message: "",
    },
    errors: {},
    submitted: false,
  };

  validate = () => {
    const errors = {};
    const { formFields } = this.state;
    if (formFields.name.trim() === "") {
      errors.name = "Name is required.";
    }
    if (formFields.email.trim() === "") {
      errors.email = "Email is required.";
    }
    if (formFields.message.trim() === "") {
      errors.message = "Message is required.";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSuccess = () => {
    this.setState({
      formFields: {},
      errors: {},
      submitted: true,
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    fetch("https://submit-form.com/L9k5uaYmKD2SVMG17Fq1F", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(this.state.formFields),
    })
      .then(this.handleSuccess)
      .catch(function(error) {
        console.error(error);
      });
  };

  validateProperty = ({ name, value }) => {
    if (name === "name") {
      if (value.trim() === "") return "Name is required.";
    }
    if (name === "email") {
      if (value.trim() === "") return "Email is required.";
    }
    if (name === "message") {
      if (value.trim() === "") return "Message is required.";
    }
  };

  handleChange = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const formFields = { ...this.state.formFields };
    formFields[input.name] = input.value;
    this.setState({ formFields, errors });
  };

  render() {
    const { formFields, errors, submitted } = this.state;
    return (
      <section id="contact">
        <div className="gradient-contact">
          <div className="gradient-contact-before"></div>
          <div
            data-aos="fade-in"
            className="container mx-auto p-5 pt-16 sm:pt-12 mb-20 text-center"
          >
            <h1 className="text-3xl xl:text-4xl font-semibold mb-6">
              Contact me
            </h1>
            <h2 className="text-lg xl:text-xl font-light mb-16">
              Interested in working together? Please fill out the form bellow.
            </h2>
            <form
              onSubmit={this.handleSubmit}
              target="_self"
              autoComplete="off"
            >
              <div className="w-11/12 sm:w-150 md:w-160 xl:w-220 mx-auto">
                <div className="sm:flex flex-row sm:mb-8">
                  <div className="w-full sm:w-1/2 flex flex-col text-left mb-6 sm:mb-0 sm:mr-8">
                    <label
                      htmlFor="name"
                      className="mb-2 text-gray-600 font-light"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={formFields.name}
                      onChange={this.handleChange}
                      className="border rounded outline-none h-10 xl:h-12 p-2"
                    />
                    {errors["name"] && (
                      <div className="bg-red-200 py-1 px-2 rounded font-light text-red-700">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="w-full sm:w-1/2 flex flex-col text-left mb-6 sm:mb-0">
                    <label
                      htmlFor="email"
                      className="mb-2 text-gray-600 font-light"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formFields.email}
                      onChange={this.handleChange}
                      className="border rounded outline-none h-10 xl:h-12 p-2"
                    />
                    {errors["email"] && (
                      <div className="bg-red-200 py-1 px-2 rounded font-light text-red-700">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col text-left">
                  <label
                    htmlFor="message"
                    className="mb-2 text-gray-600 font-light"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    value={formFields.message}
                    onChange={this.handleChange}
                    className="border rounded outline-none h-24 sm:h-32 xl:h-48 p-2"
                  />
                  {errors["message"] && (
                    <div className="bg-red-200 py-1 px-2 rounded font-light text-red-700">
                      {errors.message}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="mt-8 border-2 border-brand-blue-500 hover:bg-brand-blue-500 text-brand-blue-500 rounded-2xl px-10 py-2 font-normal text-lg transition duration-300 ease-in-out hover:text-white focus:outline-none"
                >
                  Submit
                </button>
              </div>
              {submitted ? (
                <div className="mx-auto flex flex-col items-center font-light mt-8 text-lg px-2 py-4">
                  <IoIosCheckmarkCircleOutline
                    size={50}
                    className="text-green-500"
                  />
                  <p className="mt-2">
                    Thank you for reaching out! I will get back to you as soon
                    as possible.
                  </p>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default SectionContact;
